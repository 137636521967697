<script setup lang="ts">
import type { Toast, ToastType } from '@/composables/use-toasts/types';

const { toasts, removeToast } = useToasts();

function onToastClick(toast: Toast) {
  if (toast.isDismissible) {
    removeToast(toast.id);
  }
}

function getToastType(toast: Toast): ToastType {
  return toast.type || 'default';
}
</script>

<template>
  <div class="toasts-provider">
    <div
      v-for="toast in toasts"
      :key="toast.id"
      class="toasts-provider__toast"
      :class="getToastType(toast)"
      @click="onToastClick(toast)">
      {{ toast.label }}
    </div>
  </div>
</template>

<style lang="scss" src="./style.scss" />
