import type { FluentResource } from '@fluent/bundle';
import { FluentBundle } from '@fluent/bundle';

/**
 * Generates FluentBundles from the given data.
 *
 * @param {object} data - The data containing Fluent resources.
 * @returns An array of objects containing keys and FluentBundles.
 */
export function generateFluentBundles(data: object) {
  return Object.entries(data).map(([lang, resource]) => {
    const bundle = new FluentBundle(lang);
    bundle.addResource(<FluentResource>resource);

    return {
      lang,
      bundle,
    };
  });
}
