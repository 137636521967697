export default defineNuxtRouteMiddleware(async (to) => {
  if (to.name !== 'profile') {
    return true;
  }

  const { isAuth, fetchProfile } = useAuth();
  await fetchProfile();

  if (!isAuth.value) {
    return navigateTo('/');
  }
});
