/*
|--------------------------------------------------------------------------
| Localization configuration
|--------------------------------------------------------------------------
|
*/

/**
 * Default language
 */
export const defaultLang = 'en';
