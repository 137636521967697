import type { Sorting } from '@/config/sorting';
import { SortDefault, SortOptions } from '@/config/sorting';

export const useSortingStore = defineStore('sort', () => {
  const { $analytics } = useNuxtApp();

  const options = SortOptions;
  const initSorting = SortDefault;
  const sorting = ref<Sorting>(initSorting);
  const _applyAfterReset = ref<Sorting>(initSorting);

  const currentSortingOption = computed<Sorting>(() =>
    options.find(sort => sort.id === sorting.value.id) || initSorting,
  );

  function updateSortingByName(name: string): Sorting {
    const sort = options.find(({ value }) =>
      value === name,
    );
    sorting.value = sort || initSorting;

    $analytics.trackEvent('apply_sorting', 'click', {
      chosen_sorting_value: name,
    });
    return sorting.value;
  }

  function productSortingOptions(): Sorting[] {
    return options.filter(sort => sort.availability !== true);
  }

  function availabilitySortingOptions(): Sorting[] {
    return options.filter(sort => sort.availability !== false);
  }

  function resetSorting(): void {
    sorting.value = _applyAfterReset.value;
    _applyAfterReset.value = initSorting;
  }

  function applySortToState(sort: Sorting): void {
    _applyAfterReset.value = sort;
  }

  return {
    sorting,
    initSorting,
    currentSortingOption,
    productSortingOptions: productSortingOptions(),
    availabilitySortingOptions: availabilitySortingOptions(),
    updateSortingByName,
    applySortToState,
    resetSorting,
  };
});
