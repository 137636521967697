import { $t } from '@/localization';

/*
|--------------------------------------------------------------------------
| Privacy policy fields
|--------------------------------------------------------------------------
|
*/
export const privacyPolicyFields = [
  { title: $t('privacy-policy'), description: $t('privacy-policy-description') },
  { title: $t('privacy-policy-information-you-provide'), description: $t('privacy-policy-information-you-provide-description') },
  { title: $t('privacy-policy-information-we-collect'), description: $t('privacy-policy-information-we-collect-description') },
  { title: $t('privacy-policy-ways-share'), description: $t('privacy-policy-ways-share-description') },
  { title: $t('privacy-policy-ways-use'), description: $t('privacy-policy-ways-use-description') },
  { title: $t('privacy-policy-storing'), description: $t('privacy-policy-storing-description') },
  { title: $t('privacy-policy-requesting'), description: $t('privacy-policy-requesting-description') },
  { title: $t('privacy-policy-cookies'), description: $t('privacy-policy-cookies-description') },
  { title: $t('privacy-policy-links'), description: $t('privacy-policy-links-description') },
  { title: $t('privacy-policy-contacting'), description: $t('privacy-policy-contacting-description') },
];
