import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/app/src/app/middleware/auth.global.ts";
import hello_45global from "/app/src/app/middleware/hello.global.ts";
import trailing_45slash_45global from "/app/src/app/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  hello_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}