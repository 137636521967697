import MobileDetect from 'mobile-detect';

interface ParsedUserAgent {
  browser: string | undefined;
  os: string | undefined;
  device: string;
}

function getUserAgent(): string {
  const headers = useRequestHeaders();
  return import.meta.server ? headers['user-agent'] : navigator.userAgent;
}

function parseUserAgent(ua: string): ParsedUserAgent {
  const result = {} as ParsedUserAgent;

  // browser
  switch (true) {
    case /firefox|fxios/i.test(ua):
      result.browser = 'Firefox';
      break;
    case /chrome|crios|crmo/i.test(ua):
      result.browser = 'Chrome';
      break;
    case /safari/i.test(ua):
      result.browser = 'Safari';
      break;
    case /edg/i.test(ua):
      result.browser = 'Edge';
      break;
    default:
      result.browser = undefined;
  }

  // os
  switch (true) {
    case /windows/i.test(ua):
      result.os = 'Windows';
      break;
    case /macintosh|mac os x/i.test(ua):
      result.os = 'Mac OS';
      break;
    case /android/i.test(ua):
      result.os = 'Android';
      break;
    case /linux/i.test(ua):
      result.os = 'Linux';
      break;
    case /iphone|ipad|ipod/i.test(ua):
      result.os = 'iOS';
      break;
    default:
      result.os = undefined;
  }

  // device
  switch (true) {
    case /mobile/i.test(ua):
      result.device = 'Mobile';
      break;
    case /tablet/i.test(ua):
      result.device = 'Tablet';
      break;
    default:
      result.device = 'Desktop';
  }

  return result;
}

export function isMobileDevice(): boolean {
  const userAgent = getUserAgent();
  const md = new MobileDetect(userAgent);
  return md.phone() !== null || md.mobile() === 'UnknownMobile';
}

export function isSafariBrowser(): boolean {
  const userAgent = getUserAgent();
  const parsedUserAgent = parseUserAgent(userAgent);
  return parsedUserAgent.browser === 'Safari';
};
