import type { Directive } from '@vue/runtime-core';
import type { DirectiveBinding } from 'vue';

interface HTMLScrollElement extends HTMLElement {
  __cleanup__?: () => void;
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('sticky-scroll', stickyScroll());
});

function stickyScroll(): Directive {
  return {
    mounted: (el: HTMLScrollElement, binding: DirectiveBinding<boolean | undefined>) => {
      if (binding.value === false) {
        return;
      }

      const classList = el.classList;
      el.classList.add('position-sticky');

      let ticking = false;
      const handleScroll = () => {
        if (!ticking) {
          requestAnimationFrame(() => {
            if (window.scrollY + 1 >= el.offsetTop) {
              classList.add('filter-fixed');
            } else {
              classList.remove('filter-fixed');
            }
            ticking = false;
          });
          ticking = true;
        }
      };

      window.addEventListener('scroll', handleScroll);
      handleScroll();

      el.__cleanup__ = () => {
        window.removeEventListener('scroll', handleScroll);
      };
    },
    unmounted: (el: HTMLScrollElement) => {
      if (el.__cleanup__) {
        el.__cleanup__();
        el.__cleanup__ = undefined;
      }
    },
  };
}
