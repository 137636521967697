import { $t } from '@/localization';
import type { User } from '@/shared/types';
import siteConfig from '~/config/site';
import LogInDrawer from '@/components/mobile/login-drawer/LogInDrawer.vue';

const user = ref<User | null>(null);

export function useAuth() {
  const route = useRoute();
  const { $api, $analytics } = useNuxtApp();
  const { showModal, hideModal } = useModal();
  const { showDrawer, hideDrawer } = useMobileDrawer();
  const access_token = useCookie('access_token');

  const { siteName } = siteConfig;

  const isAuth = computed(() => !!user.value);

  async function fetchProfile(): Promise<void> {
    if (!access_token.value || user.value?.email) {
      return;
    }
    try {
      user.value = await $api<User>('/client/profile');
    } catch {
    }
  }

  async function logOut() {
    await useApi('/auth/logout');
    $analytics.trackEvent('click_logout', 'click');

    user.value = null;
    if (route.name === 'profile') {
      navigateTo({ name: 'home' });
    }
  }

  const showAuthModal = (isMobile?: boolean): void => {
    if (isMobile) {
      showDrawer('login', {
        component: LogInDrawer,
        title: $t('login-title'),
        subTitle: $t('login-subtitle', { siteName }),
        zOffset: 10000,
      });
      return;
    }
    const component = defineAsyncComponent(() => import('@/components/login-modal/LogInModal.vue'));
    showModal({ component });
  };

  const hideAuthModal = (isMobile?: boolean): void => {
    if (isMobile) {
      hideDrawer('login');
      return;
    }
    hideModal();
  };

  return {
    user,
    isAuth,
    fetchProfile,
    logOut,
    showAuthModal,
    hideAuthModal,
  };
}
