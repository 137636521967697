import type { HandoverDate } from '@/shared/types';
import type { SelectOptionChildren } from '@/packages/app-ui/src/options-list/types';

export function handoverDate(value: string | null): string {
  if (!value) {
    return '';
  }

  const date = new Date(value);
  const year = date.getFullYear();
  const quarter = Math.ceil((date.getMonth() + 1) / 3);
  return `Q${quarter} ${year}`;
}

export function handoverSelect(handover: HandoverDate[] | undefined): SelectOptionChildren[] | undefined {
  if (!handover) {
    return handover;
  }
  return handover.map(({ title, filter, filter_value }) => ({
    label: title,
    name: `${filter}:${new Date(filter_value).getFullYear()}`,
  }));
}
