import { createFluentVue } from 'fluent-vue';

import { defaultLang } from '@/config/localization';
import { getFluentBundle } from '@/localization/bundles';

export const fluentVue = createFluentVue({
  bundles: [getFluentBundle(defaultLang)!],
});

/**
 * Shorthand function to format translations using FluentVue instance.
 * Function for formatting translations.
 */
export const $t = fluentVue.format;
