<script lang="ts" setup>
import type { AppButtonProps } from './types';
import AppIcon from '@/packages/app-ui/src/icon/AppIcon.vue';

const props = withDefaults(defineProps<AppButtonProps>(), {
  size: 'medium',
  type: 'primary',
  rounded: true,
  loading: false,
  showBadge: false,
  xAlign: 'center',
  outlined: false,
});

const classNames = computed(() => {
  return [
    `app-button_${props.size}`,
    `app-button_${props.type}`,
    `align-${props.xAlign}`,
    { 'has-icon': props.icon },

    props.rounded
      ? 'app-button_rounded'
      : '',

    props.loading
      ? 'app-button_loading'
      : '',

    props.disabled
      ? 'app-button_disabled'
      : '',

    props.outlined
      ? 'app-button_outlined'
      : '',
  ];
});
const iconSize = computed(() => {
  const sizes = {
    medium: 24,
    small: 20,
  };
  return sizes[props.size];
});
</script>

<template>
  <button class="app-button" :class="classNames" :disabled="props.disabled">
    <slot>
      <div
        v-if="icon"
        class="app-button__icon">
        <AppIcon
          :name="icon"
          :size="iconSize" />
      </div>
      <span
        v-if="label"
        class="app-button__label">
        {{ label }}
      </span>
      <div v-if="loading" class="app-button__spinner" />
      <div v-if="showBadge" class="app_button__badge" />
    </slot>
  </button>
</template>

<style lang="scss" src="./style.scss" />
