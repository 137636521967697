<script setup lang="ts">
const { component, bindData, bindEmits, isOpen, className, hideModal } = useModal();

const modalRef = ref<HTMLDivElement | null>(null);

function onClickOutside(e: MouseEvent) {
  const target = e.target as HTMLDivElement;
  const modalContainer = unref(modalRef);
  if (target === modalContainer) {
    hideModal();
  }
}

onMounted(() => {
  document.addEventListener('click', onClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', onClickOutside);
});
</script>

<template>
  <div
    ref="modalRef"
    class="modal-provider"
    :class="[{ show: isOpen }, className]">
    <component
      :is="component"
      v-if="component"
      v-bind="bindData"
      v-on="bindEmits" />
  </div>
</template>

<style lang="scss" src="./style.scss" />
