import type { NewToast, Toast } from './types';

const DEFAULT_TOAST_DURATION_MS = 3000;

const toasts = ref<Toast[]>([]);

export function useToasts() {
  function removeToast(toastId: number) {
    toasts.value = toasts.value.filter((toast) => {
      if (toast.id === toastId) {
        clearTimeout(toast.timeout);
        return false;
      }

      return true;
    });
  }

  function addToast(toast: NewToast) {
    const id = new Date().getTime();
    toasts.value.push({
      ...toast,
      id,
      timeout: setTimeout(() => {
        removeToast(id);
      }, toast.duration || DEFAULT_TOAST_DURATION_MS),
    });
  }

  return {
    toasts,
    addToast,
    removeToast,
  };
}
