import type { FluentBundle } from '@fluent/bundle';
import { generateFluentBundles } from '@/localization/utils';
import { fluentResources } from '@/localization/resources';
import type { Locale } from '@/localization/types';

export const bundles = generateFluentBundles(fluentResources);

/**
 * Retrieves the FluentBundle corresponding to the given locale key.
 *
 * @param {Locale} lang - The locale key.
 * @returns {FluentBundle} The FluentBundle corresponding to the given locale key.
 */
export function getFluentBundle(lang: Locale): FluentBundle | undefined {
  return bundles.find(bundle => bundle.lang === lang)?.bundle;
}
