import type { Directive } from '@vue/runtime-core';
import type { DirectiveBinding } from 'vue';
import { handoverDate } from '@/shared/utils';
import { $t } from '@/localization';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('handover-date', handoverDateDirective());
});

function handoverDateDirective(): Directive {
  return {
    created: (el: HTMLElement, binding: DirectiveBinding<string>) => {
      if (binding.value) {
        const handover = handoverDate(binding.value);
        el.textContent = binding.modifiers.translate ? $t('handover', { handover }) : handover;
      }
    },
  };
}
