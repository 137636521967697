import type { Reactive } from 'vue';

export function syncKeys<S extends object, R extends object>(source: S, reference: R): Partial<S> | null {
  const refKeys = new Set(Object.keys(reference));
  const sourceObject: Partial<S> = {};

  for (const key in source) {
    if (refKeys.has(key)) {
      sourceObject[key] = source[key];
    }
  }

  return Object.keys(sourceObject).length ? sourceObject : null;
}

export function deepCloneReactive<T>(obj: T): Reactive<T> {
  const rawObj = toRaw(obj);
  const clonedObj: any = Array.isArray(rawObj) ? [] : {};

  for (const key in rawObj) {
    if (typeof rawObj[key] === 'object' && rawObj[key] !== null) {
      clonedObj[key] = deepCloneReactive(rawObj[key]);
    } else {
      clonedObj[key] = rawObj[key];
    }
  }

  return reactive(clonedObj) as Reactive<T>;
}

export function deepCloneObject<T>(obj: T): T {
  const objectCopy = {} as T;

  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      objectCopy[key] = deepCloneObject(obj[key]);
    } else {
      objectCopy[key] = obj[key];
    }
  }

  return objectCopy;
}

export function copyObjectWithArrayValues<T>(obj: T): T {
  const result = {} as { [key in keyof T]: any };

  for (const key in obj) {
    const typedKey = key as keyof T;
    if (Array.isArray(obj[typedKey])) {
      const array = JSON.stringify(toRaw(obj[typedKey]));
      result[typedKey] = JSON.parse(array);
    } else {
      result[typedKey] = toRaw(obj[typedKey]);
    }
  }

  return result;
}
