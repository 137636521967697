const isOpen = shallowRef(true);

export function useChat() {
  const { $analytics } = useNuxtApp();

  const chatWrapperElement = shallowRef<HTMLDivElement | null>(null);

  function loadChatScript(time: number): void {
    setTimeout(() => {
      isOpen.value = false;

      const script = document.createElement('script');
      script.src = 'https://static.axcapital.ae/assets/chat/index.js';
      script.async = true;
      document.body.appendChild(script);
    }, time);
  }

  async function openChat() {
    chatWrapperElement.value = document.querySelector('.axchat-main');

    if (chatWrapperElement.value) {
      const chatHeaderElement: HTMLElement | null = document.querySelector('.axchat-main .axchat-header');
      chatHeaderElement?.click();

      isOpen.value = true;
      await nextTick();
      const observer = new MutationObserver(mutationListener);

      observer.observe(chatWrapperElement.value, { attributes: true, subtree: true });
      listenChatSendMessage();
    }

    $analytics.trackEvent('chat_opened', 'click');
  }

  function mutationListener(mutations: MutationRecord[], observer: MutationObserver): void {
    mutations.forEach((mutation) => {
      const target = mutation.target as HTMLElement;

      const isHide = target.className.includes('hide-chat-resize');
      if (isHide) {
        isOpen.value = false;
        observer.disconnect();
        return;
      }

      const isForm = target.className.includes('axchat-form-wrapper');
      if (isForm) {
        const form = (mutation.target as HTMLElement).querySelector('form');
        form?.addEventListener('submit', fetchAnalyticFormSubmit, { once: true });
      }
    });
  }

  function listenChatSendMessage(): void {
    document.querySelector('.axchat-send-form-wrapper')?.addEventListener('submit', fetchAnalyticSendMessage);
  }

  function fetchAnalyticSendMessage() {
    $analytics.trackEvent('chat_send_message', 'click');
  }

  function fetchAnalyticFormSubmit() {
    $analytics.trackEvent('chat_form_submit', 'click');
  }

  onUnmounted(() => {
    document.querySelector('.axchat-send-form-wrapper')?.removeEventListener('submit', fetchAnalyticSendMessage);
  });

  return {
    isOpen,
    openChat,
    loadChatScript,
  };
}
