import { Currency, type CurrencyKey, defaultCurrency } from '@/config/currency';
import { Square, type SquareKey, defaultSquare } from '@/config/square';

interface Config {
  currency: string;
  square: string;
}

export const useConfigStore = defineStore('config', () => {
  const { $analytics } = useNuxtApp();

  const currency = ref(defaultCurrency.toString());
  const square = ref(defaultSquare.toString());

  const currencyLabel = computed(() => {
    return getLabel('currency', Currency);
  });

  const squareLabel = computed(() => {
    return getLabel('square', Square);
  });

  function getLabel(configKey: keyof Config, type: any): string {
    return Object.keys(type).find((key) => {
      if (configKey === 'currency') {
        return currency.value === type[key].toString();
      } else if (configKey === 'square') {
        return square.value === key;
      }
      return false;
    }) || '';
  }

  const currencyOptions = computed(() =>
    Object.entries(Currency).map(([key, value]) => ({ label: key, name: value.toString() })),
  );

  const squareOptions = computed(() =>
    Object.entries(Square).map(([key, value]) => ({ label: value, name: key })),
  );

  function setCurrency(newCurrency: string | number, trackEvent: boolean = true): void {
    currency.value = newCurrency.toString();

    if (trackEvent) {
      $analytics.trackEvent('apply_price_currency', 'click', {
        currency_value: currencyLabel.value,
      });
    }
  }

  function setSquare(newSquare: string | number, trackEvent: boolean = true): void {
    square.value = newSquare.toString();

    if (trackEvent) {
      $analytics.trackEvent('apply_area_measure', 'click', {
        size_measure_value: squareLabel.value,
      });
    }
  }

  function getCurrencyLabel(currencyCode: number): string {
    return Object.keys(Currency).find(key => Currency[key as CurrencyKey] === currencyCode) || currencyLabel.value;
  }

  function getSquareLabel(squareCode: string): string {
    return Object.keys(Square).find(key => Square[key as SquareKey] === squareCode) || squareLabel.value;
  }

  return {
    currency,
    square,
    currencyLabel,
    squareLabel,
    currencyOptions,
    squareOptions,
    setCurrency,
    setSquare,
    getCurrencyLabel,
    getSquareLabel,
  };
});
