import { useGtm } from '@gtm-support/vue-gtm';
import type { AnalyticEvent } from '@/shared/types';

export default defineNuxtPlugin(() => {
  let gtm = useGtm();
  const route = useRoute();
  const { user } = useAuth();

  const gtmParams = computed(() => {
    return {
      user_id: user.value?.id,
      is_agent: !!user.value?.is_agent,
      page_name: route.name,
      page_path: window.location.origin + window.location.pathname,
      ax_chat_uuid: localStorage.getItem('ax-chat-uuid'),
    };
  });

  function trackEvent(event: AnalyticEvent, action: string, params?: Record<string, any>): void {
    if (!gtm) {
      gtm = useGtm();
    }

    gtm?.trackEvent({
      event,
      action,
      ...gtmParams.value,
      ...params,
    });
  }

  return {
    provide: {
      analytics: { trackEvent },
    },
  };
});
