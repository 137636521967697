import { $t } from '@/localization';

export const projectsFetchLimit = 9;

export interface Sorting {
  id: string;
  label: string;
  value: string;
  availability?: boolean;
}

export const SortOptions: Sorting[] = [
  {
    id: 'Cheapest',
    label: $t('price-low-high'),
    value: 'cheap',
  },
  {
    id: 'MostExpensive',
    label: $t('price-high-low'),
    value: 'expensive',
  },
  {
    id: 'EarlyHandover',
    label: $t('handover-early-late'),
    value: 'early-handover',
    availability: false,
  },
  {
    id: 'LateHandover',
    label: $t('handover-late-early'),
    value: 'late-handover',
    availability: false,
  },
  {
    id: 'Largest',
    label: $t('square-largest'),
    value: 'largest',
    availability: true,
  },
  {
    id: 'Smallest',
    label: $t('square-smallest'),
    value: 'smallest',
    availability: true,
  },
];

export const SortDefault = SortOptions[0];
