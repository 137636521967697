import type { CacheControllerMap } from './types';

export const useApiCacheControllerStore = defineStore('api-cache-controller', () => {
  const keys = useLocalStorage<CacheControllerMap>(
    'api-cache-controller-keys',
    new Map(),
    {
      serializer: {
        read: (value: string) => new Map(JSON.parse(value)),
        write: (value: CacheControllerMap) => JSON.stringify(Array.from(value.entries())),
      },
    },
  );

  function _getUrlKey(url: string): string {
    const segments = url.replace(/^\/|\/$/g, '').split('/');
    return segments[0];
  }

  function reloadCache(key: string): void {
    const has = keys.value.has(key);
    const updateKey = has ? (keys.value!.get(key)! + 1) : 1;
    keys.value.set(key, updateKey);
  }

  function getReloadKey(url: string): number | undefined {
    const key = _getUrlKey(url);
    return keys.value.get(key);
  }

  return {
    reloadCache,
    getReloadKey,
  };
});
