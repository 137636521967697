import type { AsyncData, UseFetchOptions } from '#app';
import type { KeysOf, PickFrom } from '#app/composables/asyncData';
import type { $Fetch } from 'nitropack';

interface Error {
  statusCode: number;
  message: string;
  stack: string;
}

export function useApi<T, E = Error>(
  url: string | (() => string),
  useOptions?: Omit<UseFetchOptions<T>, 'default'> & { default?: () => T | Ref<T> },
): AsyncData<T | PickFrom<T, KeysOf<T>>, E | null> {
  return useFetch(url, {
    ...useOptions,
    lazy: true,
    onRequest({ request, options }) {
      const onParamsRequest = useOptions?.onRequest;
      if (onParamsRequest && typeof onParamsRequest === 'function') {
        onParamsRequest({ request, options });
      }

      const { getReloadKey } = useApiCacheControllerStore();

      const reload_key = getReloadKey(request.toString());
      if (!reload_key) {
        return;
      }

      options.query = {
        ...options.query,
        reload_key,
      };
    },
    $fetch: useNuxtApp().$api as $Fetch,
  });
}
