import type { Directive } from '@vue/runtime-core';
import type { DirectiveBinding } from 'vue';

interface DirectiveBindingParams {
  min_zoom: number;
  max_zoom: number;
}

interface TouchEvents {
  type: keyof HTMLElementEventMap;
  event: (event: Event) => void;
}

interface TouchDataParams {
  startX: number;
  startY: number;
  lastX: number;
  lastY: number;
  initialDistance: number;
  pinchScale: number;
  isDragging: boolean;
}

const DefaultParams: DirectiveBindingParams = {
  min_zoom: 1,
  max_zoom: 3,
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('image-touch-zoom', imageTouchZoomDirective());
});

function imageTouchZoomDirective(): Directive {
  const touchEvents: TouchEvents[] = [];

  return {
    mounted: (el: HTMLElement, binding?: DirectiveBinding<DirectiveBindingParams>) => {
      const scale = ref(1); // Масштабирование изображения
      const position = reactive({ x: 0, y: 0 }); // Координаты для перемещения
      const { min_zoom, max_zoom } = binding?.value || DefaultParams;
      let animationFrameId: number | null = null;

      const touchData = reactive<TouchDataParams>({
        startX: 0,
        startY: 0,
        lastX: 0,
        lastY: 0,
        initialDistance: 0,
        pinchScale: 1,
        isDragging: false,
      });

      function limitPosition(): void {
        const imageWidth = el.offsetWidth * scale.value;
        const imageHeight = el.offsetHeight * scale.value;

        const maxX = Math.max(0, (imageWidth - el.offsetWidth) / 2);
        const maxY = Math.max(0, (imageHeight - el.offsetHeight) / 2);

        position.x = Math.max(-maxX, Math.min(position.x, maxX));
        position.y = Math.max(-maxY, Math.min(position.y, maxY));

        updatePosition();
      }

      function updateTransform(): void {
        limitPosition();
        animationFrameId = requestAnimationFrame(updateTransform);
      }

      function onTouchStart(event: Event): void {
        if (!(event instanceof TouchEvent)) {
          return;
        }

        if (event.touches.length === 1) {
          touchData.startX = event.touches[0].clientX;
          touchData.startY = event.touches[0].clientY;
          touchData.lastX = position.x;
          touchData.lastY = position.y;
          touchData.isDragging = true;
        } else if (event.touches.length === 2) {
          touchData.initialDistance = getDistance(event.touches);
          touchData.pinchScale = scale.value;
        }

        if (!animationFrameId) {
          animationFrameId = requestAnimationFrame(updateTransform);
        }
      }

      function onTouchMove(event: Event): void {
        if (!(event instanceof TouchEvent)) {
          return;
        }

        event.preventDefault();
        if (event.touches.length === 1 && touchData.isDragging) {
          const deltaX = event.touches[0].clientX - touchData.startX;
          const deltaY = event.touches[0].clientY - touchData.startY;
          position.x = touchData.lastX + deltaX;
          position.y = touchData.lastY + deltaY;
          limitPosition();
        } else if (event.touches.length === 2) {
          const currentDistance = getDistance(event.touches);
          const scaleChange
              = (currentDistance / touchData.initialDistance) * touchData.pinchScale;
          scale.value = Math.min(max_zoom, Math.max(min_zoom, scaleChange));
          limitPosition();
        }
      }

      function onTouchEnd(): void {
        touchData.isDragging = false;
        touchData.initialDistance = 0;

        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId);
          animationFrameId = null;
        }
      }

      function getDistance(touches: TouchList): number {
        const dx = touches[0].clientX - touches[1].clientX;
        const dy = touches[0].clientY - touches[1].clientY;
        return Math.sqrt(dx * dx + dy * dy);
      }

      function updatePosition(): void {
        el.style.transform = `translate(${position.x}px, ${position.y}px) scale(${scale.value})`;
      }

      touchEvents.push({ type: 'touchstart', event: onTouchStart });
      touchEvents.push({ type: 'touchmove', event: onTouchMove });
      touchEvents.push({ type: 'touchend', event: onTouchEnd });

      touchEvents.forEach(({ type, event }) => el.addEventListener(type, event));
    },
    unmounted(el: HTMLElement) {
      touchEvents.forEach(({ type, event }) => el.removeEventListener(type, event));
    },
  };
}
