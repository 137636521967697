import type { DrawerState, ShowDrawerOptions } from './types';

const state = reactive<DrawerState>({
  drawers: [],
});

function showDrawer<T, E>(key: string, options: ShowDrawerOptions<T, E>) {
  state.drawers.push({
    key,
    ...options,
    component: markRaw(options.component),
    topComponent: options.topComponent ? markRaw(options.topComponent) : undefined,
    show: true,
    bindData: options.data,
    bindEmits: options.emits || {},
  });

  document.body.classList.add('overflow-hidden');
}

function hideDrawer(key?: string) {
  state.drawers = key ? state.drawers.filter(drawer => drawer.key !== key) : [];

  if (!state.drawers.length) {
    document.body.classList.remove('overflow-hidden');
  }
}

export function useMobileDrawer() {
  return {
    ...toRefs(state),
    showDrawer,
    hideDrawer,
  };
}
