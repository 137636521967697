import type { Directive } from '@vue/runtime-core';
import type { DirectiveBinding } from 'vue';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('scroll-to-top', scrollToTopDirective());
});

function scrollToTopDirective(): Directive {
  const route = useRoute();
  let bindRoute: string | undefined;

  const handleScroll = () => {
    if (bindRoute && route.path !== bindRoute) {
      return;
    }

    if (window?.scrollY > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  return {
    mounted: (el: HTMLElement, binding: DirectiveBinding<string | undefined>) => {
      bindRoute = binding.value;
      el.addEventListener('click', handleScroll);
    },
    unmounted: (e) => {
      e.removeEventListener('click', handleScroll);
    },
  };
}
