import type { BaseFilters, ObjectType } from '@/shared/types';
import {
  FiltersMaps,
  type FiltersState,
} from '@/stores/filters/types';
import { useConfigStore } from '@/stores/config';
import { deepCloneReactive } from '@/shared/utils/object';
import type { LocationQueryRaw } from '#vue-router';

const defaultFilters = {
  priceFrom: '',
  priceTo: '',
  squareFrom: '',
  squareTo: '',
  handoverDates: [],
  unitTypes: [],
  bedrooms: [],
  bathrooms: [],
  amenities: [],
  projectIds: [],
};

export const useFiltersStore = defineStore('filters', () => {
  const route = useRoute();

  const configStore = useConfigStore();
  const { currency, square } = storeToRefs(configStore);

  const initialGlobalFilters = deepCloneReactive<FiltersState>(defaultFilters);
  const filters = deepCloneReactive<FiltersState>(defaultFilters);
  const filtersParams = shallowRef<BaseFilters>();

  const _applyAfterReset = ref<Partial<FiltersState>>();

  const updateConfigFlags = reactive({
    currency: false,
    square: false,
  });

  const hasAcceptedFilters = computed(() => {
    const query = {
      ...getCurrentFilterParams(),
      smt: undefined,
      cur: undefined,
    };
    return !!Object.values(query).filter(val => val !== undefined).length;
  });

  const slugsParams = ref(route.params?.slug);

  const params = computed(() => ({
    slugs: slugsParams.value,
    currencyCode: currency.value,
    measurement: square.value,
  }));

  async function fetchFilters(): Promise<void> {
    await useApi<BaseFilters>('/projects/filters', {
      params,
      onResponse({ response }): Promise<void> | void {
        filtersParams.value = prepareFilterResponse(response._data);
        applyPriceRange();
      },
      dedupe: 'defer',
    });

    applyPriceRange();
  }

  function prepareFilterResponse(response: BaseFilters): BaseFilters {
    const result = { ...response };
    result.area.from.value = Math.floor(result.area.from.value);
    result.area.to.value = Math.ceil(result.area.to.value);
    result.price_range.from = Math.floor(result.price_range.from);
    result.price_range.to = Math.ceil(result.price_range.to);

    return result;
  }

  function applyPriceRange(): void {
    if (!filtersParams?.value) {
      return;
    }

    const { price_range, area } = filtersParams.value;
    const dataParams: ObjectType<string> = {
      priceFrom: price_range.from.toString(),
      priceTo: price_range.to.toString(),
      squareFrom: area.from.value.toString(),
      squareTo: area.to.value.toString(),
    };
    Object.assign(initialGlobalFilters, dataParams);

    if (updateConfigFlags.currency) {
      filters.priceFrom = dataParams.priceFrom;
      filters.priceTo = dataParams.priceTo;
      updateConfigFlags.currency = false;
    } else {
      filters.priceFrom = filters.priceFrom || dataParams.priceFrom;
      filters.priceTo = filters.priceTo || dataParams.priceTo;
    }

    if (updateConfigFlags.square) {
      filters.squareFrom = dataParams.squareFrom;
      filters.squareTo = dataParams.squareTo;
      updateConfigFlags.square = false;
    } else {
      filters.squareFrom = filters.squareFrom || dataParams.squareFrom;
      filters.squareTo = filters.squareTo || dataParams.squareTo;
    }
  }

  function getCurrentFilterParams(baseQuery: LocationQueryRaw = {}): LocationQueryRaw {
    if (!initialGlobalFilters.priceFrom) {
      return { ...route.query };
    }

    return filtersToQuery(filters, { ...baseQuery });
  }

  function filtersToQuery(filterParams: FiltersState, query: LocationQueryRaw = {}): LocationQueryRaw {
    for (const k in filterParams) {
      const key = k as keyof FiltersState;
      const mapKey = FiltersMaps[key];
      const value = Array.isArray(filterParams[key]) ? filterParams[key].join(',') : filterParams[key];
      const isSame = initialGlobalFilters[key] === value;

      query[mapKey] = value && !isSame ? value : '';
    }
    return _cleanQuery({ ...query });
  }

  function _cleanQuery(query: LocationQueryRaw): LocationQueryRaw {
    return Object.fromEntries(Object.entries(query).filter(([_, v]) => v !== ''));
  }

  const applyFiltersToState = (data: Partial<FiltersState>) => {
    _applyAfterReset.value = { ...data };
  };

  const applyValueToFilters = (data: Partial<FiltersState>) => {
    Object.assign(filters, deepCloneReactive(data));
  };

  function deepResetFilters(): void {
    Object.assign(initialGlobalFilters, deepCloneReactive(defaultFilters));
    Object.assign(filters, deepCloneReactive(defaultFilters));
  }

  function resetFilters(): void {
    Object.assign(filters, deepCloneReactive(_applyAfterReset.value || initialGlobalFilters));
    _applyAfterReset.value = undefined;
  }

  function prepareHandoverDates(query: LocationQueryRaw): LocationQueryRaw {
    if (query?.hnd) {
      const result: LocationQueryRaw = { hnd: undefined };
      const data = query.hnd.toString().split(',');
      data.forEach((handover) => {
        const [key, value] = handover.split(':');
        result[key] = result[key] ? `${result[key]},${value}` : value;
      });
      return { ...query, ...result };
    }
    return query;
  }

  watch([currency, square], (newValue, oldValue) => {
    if (newValue[0] !== oldValue[0]) {
      updateConfigFlags.currency = true;
    } else if (newValue[1] !== oldValue[1]) {
      updateConfigFlags.square = true;
    }
  });

  watch(() => route.params.slug, (value) => {
    if (value !== slugsParams.value) {
      deepResetFilters();
      slugsParams.value = value;
    }
  });

  return {
    filters,
    initialGlobalFilters,
    filtersParams,
    hasAcceptedFilters,
    fetchFilters,
    applyFiltersToState,
    applyValueToFilters,
    prepareHandoverDates,
    filtersToQuery,
    getCurrentFilterParams,
    resetFilters,
  };
});
