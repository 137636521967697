import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_6wEQMY3tee from "/app/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import sentry_client_VdOeuw25Mb from "/app/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/app/.nuxt/sentry-client-config.mjs";
import analytics_OmzyiUGWfJ from "/app/src/app/plugins/analytics.ts";
import api_client_auCC7GWU6R from "/app/src/app/plugins/api-client.ts";
import autoanimate_nLsrN6ZPqC from "/app/src/app/plugins/autoanimate.ts";
import fluent_RsG3p9N99u from "/app/src/app/plugins/fluent.ts";
import gtm_client_yhDT7xSvgk from "/app/src/app/plugins/gtm.client.ts";
import handover_date_yQmlUskjVV from "/app/src/app/plugins/handover-date.ts";
import image_zoom_JYbsvWEuOb from "/app/src/app/plugins/image-zoom.ts";
import scroll_to_top_TxyZxdZM2z from "/app/src/app/plugins/scroll-to-top.ts";
import sticky_scroll_oUWmKBAhYR from "/app/src/app/plugins/sticky-scroll.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_6wEQMY3tee,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  analytics_OmzyiUGWfJ,
  api_client_auCC7GWU6R,
  autoanimate_nLsrN6ZPqC,
  fluent_RsG3p9N99u,
  gtm_client_yhDT7xSvgk,
  handover_date_yQmlUskjVV,
  image_zoom_JYbsvWEuOb,
  scroll_to_top_TxyZxdZM2z,
  sticky_scroll_oUWmKBAhYR
]