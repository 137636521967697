import type { ShowModalOptions, UseModalState } from './types';

const state = reactive<UseModalState>({
  component: null,
  show: false,
  bindData: {},
  className: '',
  isClosable: false,
  bindEmits: {},
});

function showModal(options: ShowModalOptions) {
  const { component, data, emits, className, isClosable } = options;
  state.component = markRaw(component);
  state.show = true;

  if (data) {
    state.bindData = data;
  }

  if (emits) {
    state.bindEmits = emits;
  }

  if (className) {
    state.className = className;
  }

  state.isClosable = !!isClosable;
}

function hideModal() {
  state.component = null;
  state.show = false;
  state.bindData = {};
  state.className = '';
  state.isClosable = false;
  state.bindEmits = {};
}

export function useModal() {
  const isOpen = computed(() => state.show);
  return {
    isOpen,
    ...toRefs(state),
    showModal,
    hideModal,
  };
}
