<script lang="ts" setup>
import type { AppIconProps } from './types';

const props = withDefaults(defineProps<AppIconProps>(), {
  size: 24,
});

/**
 * Computes the source SVG file as vue component by prop name.
 * @returns {Component} SVG file as vue component.
 */
const icon = computed(() => {
  return defineAsyncComponent(() => {
    return import(`./svg/${props.name}.svg`);
  });
});
</script>

<template>
  <component
    :is="icon"
    :width="size"
    :height="size" />
</template>
