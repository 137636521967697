import type { RouterConfig } from '@nuxt/schema';
import type { RouteRecordSingleView, RouteRecordSingleViewWithChildren } from 'vue-router';
import type { RouterOption } from '@/config/router';
import { RouterList, RouterListMobile } from '@/config/router';
import { isMobileDevice } from '@/shared/utils/device-detect';

function buildRoutes(routes: RouterOption[], isMobile: boolean): RouteRecordSingleView[] | RouteRecordSingleViewWithChildren[] {
  return routes.map(({ name, path, page, meta, children }: RouterOption) => ({
    name,
    path,
    meta,
    component: async () => await import(`~/pages/${page}/${page}.page${isMobile ? '.mobile' : ''}.vue`),
    children: children ? buildRoutes(children, isMobile) : [],
  }));
};

function routingRegister(): RouteRecordSingleView[] | RouteRecordSingleViewWithChildren[] {
  const isMobile = isMobileDevice();
  const routes = (isMobile ? RouterListMobile : RouterList);
  return buildRoutes(routes, isMobile);
}

const Routes: RouterConfig = { routes: () => routingRegister() };

export default Routes;
